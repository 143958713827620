import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { ResponsiveService } from "@/components/shared/responsive/responsive.service";

@Injectable({
    providedIn: "root",
})
export class AvailableForMobileGuard {
    constructor(
        private responsiveService: ResponsiveService,
        private router: Router,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return (
            this.responsiveService.isMobile ||
            this.router.navigateByUrl(next.data.default)
        );
    }
}
